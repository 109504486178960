import React, { useState, useEffect } from 'react';

const App = () => {

  useEffect(() => {
  }, []);

  return (
    <model-viewer src="aaa.glb" ar ar-modes="webxr scene-viewer quick-look" camera-controls tone-mapping="commerce" poster="poster.webp" shadow-intensity="1">
    <div className="progress-bar hide" slot="progress-bar">
        <div className="update-bar"></div>
    </div>
    </model-viewer>
  );
};

export default App;